import walletserve from "../services/wallet.js";

export const processHelpCenter = async (params) => {
  const { apiKey, provider, transaction_id } = params;
  const queryParams = { provider, transaction_id };
  const path = `${process.env.VUE_APP_WALLET_BASE_URL}/transactions/verify`;
  const response = await walletserve.post(path, queryParams, {
    headers: { "api-key": apiKey },
  });
  return response.data;
};
